.section {
  padding-top: 4rem;
  position: relative;
  border-bottom: none;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 4rem;
}

.bg-color-gray {
  background-color: #fafbfb !important;
}
.bg-color-white {
  background-color: #ffffff !important;
}
.bg-color-dark {
  background-color: #191919 !important;
}

.bg-color-red {
  background-color: #ad3e30 !important;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.bg-black-friday {
  background-image: url("/assets/img/wallpapers/bg-black-friday-1.jpg");
  background-size: cover;
  /* min-height: 400px;
  padding: 120px 0; */
}

.bg-light-christmas {
  background-image: url("/assets/img/wallpapers/bg-light-christmas.jpg");
  background-size: cover;
  background-position-y: bottom;
  background-position-x: right;
}

.bg-red-chunjie {
  background-image: url("/assets/img/wallpapers/bg-red-chunjie-1.jpg");
  background-size: cover;
  background-position-y: bottom;
}

.bg-red-denglong {
  background-image: url("/assets/img/wallpapers/bg-red-chunjie-2.jpg");
  background-size: cover;
  background-position-y: inherit;
  border-bottom: 5px solid #740000;
  border-top: 5px solid #740000;
}

.bg-red-snow {
  background-image: url("/assets/img/wallpapers/bg-red-snow.jpg");
  background-size: contain;
  background-repeat: repeat-x;
}

.bg-spring-field {
  background-image: url("/assets/img/wallpapers/camomile-field-h.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-spring-easter {
  background-image: url("/assets/img/wallpapers/spring-background-fleurs-oeufs-paques.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-truck-purple {
  background-image: url("/assets/img/wallpapers/bg-truck-purple.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-moving-home {
  background-image: url("/assets/img/wallpapers/moving-home-with-cardboard-boxes.jpg");
  background-size: cover;
  background-position-y: center;
  border-bottom: 5px solid #eee9d6;
}

.bg-new-year {
  background-image: url("/assets/img/wallpapers/bg-new-year-2023.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-chinese-new-year {
  background-image: url("/assets/img/wallpapers/bg-chinese-new-year-2023.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .bg-red-snow {
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
}
