/* You can add global styles to this file, and also import other style files */
/* @import "./themes/text.less"; */
@import "./themes/layout.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}
html,
body {
  padding: 0;
  margin: 0;
}

svg {
  display: initial !important;
  vertical-align: initial;
}

.slick-prev {
  z-index: 1;
  left: 25px !important;
}

.slick-next {
  right: 25px !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #c6eaff;
}

@media only screen and (max-width: 600px) {
  .ant-picker-panel-container .ant-picker-panels {
    display: grid;
  }
}

.ant-menu-horizontal {
  border-bottom: none !important;
}

.text-primary {
  color: #48a0ff !important;
}

/* Page Header Content */
/* .ant-page-header-content {
  .content {
    flex: 1;
  }

  .content p {
    margin-bottom: 1em;
  }

  .content-link a {
    margin-right: 16px;
  }

  .content-link a img {
    margin-right: 8px;
  }

  .content-image {
    margin: 0 0 0 60px;
    display: flex;
    align-items: center;
  }

  .content-image img {
    width: 100%;
  }

  @media (max-width: 768px) {
    .content-image {
      flex: 100%;
      margin: 24px 0 0;
    }
  }
} */

.fixed-bottom {
  position: fixed;
  bottom: 0;
  background: white;
  padding: 10px 20px;
}

.shadow-top {
  -webkit-box-shadow: 0 6px 10px 0 #666666;
  box-shadow: 0 6px 10px 0 #666666;
}

.border-t {
  border-top: 1px solid #eee;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.w-100 {
  width: 100%;
}

@media (max-width: 991px) {
  .sticky-mobile {
    -webkit-box-shadow: 0 -1px 10px #cccccc;
    box-shadow: 0 -1px 10px #cccccc;
    z-index: 10;
    margin: 0 !important;
    position: fixed;
    width: 100%;
    left: 0;
    background: #fafafa;
    bottom: 0;
    text-align: right;
    min-height: 50px;
    padding: 15px 10px;
  }
}

.table-checkbox .ant-checkbox-inner {
  border: 2px solid #1890ff !important;
}
.table-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  border: 0px solid #1890ff !important;
}

.ant-empty-image > img {
  margin: 0 auto;
}

/* livemode switch */
.livemode-switch > .ant-switch {
  @apply bg-slate-400;
}

.livemode-switch > .ant-switch-checked {
  @apply bg-primary;
}
